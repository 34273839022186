<template>
  <div>
    <c-search-box @enter="getList">
      <template slot="search">
        <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
          <!-- 사업장 -->
          <c-plant type="search" name="plantCd" v-model="searchParam.plantCd" @input="getList"/>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
          <!-- 기준년도 -->
          <c-datepicker
            :required="true"
            type="year"
            label="LBL00001627"
            name="patrolYear"
            v-model="searchParam.patrolYear"
          />
        </div>
        <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
          <!-- 부서 -->
          <c-dept
            type="search"
            label="점검 주관부서"
            name="vendorCd"
            :isFirstValue="false"
            v-model="searchParam.vendorCd"
          />
        </div>
        <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
          <!-- 진행단계 -->
          <c-select
            :comboItems="statusItems"
            type="search"
            itemText="codeName"
            itemValue="code"
            name="patrolCompleteFlag"
            label="LBLSTEPPROGRESS"
            v-model="searchParam.patrolCompleteFlag"
          ></c-select>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
          <c-select
            type="search"
            :editable="false"
            codeGroupCd="PATROL_TYPE_CD"
            itemText="codeName"
            itemValue="code"
            name="patrolTypeCd"
            label="점검구분"
            v-model="searchParam.patrolTypeCd"
          ></c-select>
        </div>
      </template>
    </c-search-box>
    <div class="row">
      <div class="col-7">
        <!-- 월별 점검결과 건수 -->
        <c-card title="LBL00001628" class="cardClassDetailForm bodyOverflow-none">
          <template slot="card-button">
            <q-btn-group outline >
              <c-btn label="LBLSEARCH" icon="search" @btnClicked="getList" />
            </q-btn-group>
          </template>
          <template slot="card-detail">
            <div class="col-12">
              <apexchart 
                ref="chart" 
                :height="chartHeight" 
                type="bar"
                @click="clickDataPoint"
                :width="chart.chartWidth"
                :options="chart.chartOptions" 
                :series="chart.series"></apexchart>
            </div>
          </template>
        </c-card>
      </div>
      <div class="col-5">
        <!-- 점검결과별 점검항목 -->
        <c-table
          ref="table"
          title="LBL00001629"
          :columns="grid.columns"
          :data="grid.data"
          :usePaging="false"
          :filtering="false"
          :isExcelDown="false"
          :isFullScreen="false"
          :columnSetting="false"
        >
        </c-table>
      </div>
    </div>
  </div>
</template>

<script>
import selectConfig from '@/js/selectConfig';
import VueApexCharts from "vue-apexcharts";
export default {
  name: 'patrolGraphMonth',
  props: {
    patrolInfo: {
      type: Object,
      default(){
        return {
          patrolTypeCd: '',
          title: '',
        }
      },
    },
  },
  components: {
    apexchart: VueApexCharts
  },
  data() {
    return {
      chartHeight: 500,
      seriesData: [],
      searchParam: {
        plantCd: null,
        vendorCd: null,
        useFlag: 'Y',
        patrolYear: '',
        patrolTypeCd: null,
      },
      statusItems: [
        { code: "N", codeName: "점검중" },
        { code: "Y", codeName: "점검완료" },
      ],
      grid: {
        columns: [
          {
            name: 'patrolDate',
            field: 'patrolDate',
            // 순회일
            label: 'LBL00001602',
            align: 'center',
            sortable: true,
            style: 'width:120px',
          },
          {
            name: 'patrolItemName',
            field: 'patrolItemName',
            // 점검항목
            label: 'LBL00001619',
            align: 'left',
            sortable: true,
          },
          {
            name: 'patrolResultName',
            field: 'patrolResultName',
            // 점검결과
            label: 'LBL00001620',
            align: 'center',
            sortable: true,
            style: 'width:120px',
          },
        ],
        data: [],
      },
      chart: {
        chartOptions: {
          colors: ['#008FFB','#00E396','#FEB019','#FF4560','#775DD0','#3F51B5','#03A9F4','#4CAF50','#F9CE1D','#FF9800','#A300D6','#5653FE','#1B998B','#F86624','#13D8AA'],
          title: {
            text: ''
          },
          chart: {
            type: 'bar',
            dropShadow: {
              enabled: true,
              color: '#000',
              top: 18,
              left: 7,
              blur: 10,
              opacity: 0.2
            },
            toolbar: {
              show: true
            },
            zoom: {
              enabled: false
            },
          },
          dataLabels: {
            enabled: false
          },
          stroke: {
            curve: 'smooth',
          },
          xaxis: {
            categories: ['1월','2월','3월','4월','5월','6월','7월','8월','9월','10월','11월','12월'],
          },
          grid: {
            borderColor: '#e7e7e7',
            row: {
              colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
              opacity: 0.5
            },
          },
          yaxis: {
            title: {
              text: ''
            }
          },
          fill: {
            opacity: 1
          },
          markers: {
            size: 1,
          },
          legend: {
            position: 'bottom',
            horizontalAlign: 'center',
          }
        },
        series: [],
        chartWidth: '100%',
      },
      editable: true,
      listUrl: '',
      detailUrl: '',
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
    window.addEventListener('resize', this.setSize);
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.setSize);
  },
  methods: {
    init() {
      // role setting
      this.editable = this.$route.meta.editable;
      // url setting
      this.listUrl = selectConfig.sai.patrol.graph.result.url;
      this.detailUrl = selectConfig.sai.patrol.graph.resultitem.url;
      this.searchParam.patrolTypeCd = this.patrolInfo.patrolTypeCd;
      // code setting
      this.searchParam.patrolYear = this.$comm.getThisYear();
      // list setting
      this.getList();
      this.setSize();
    },
    getList() {
      // if (!this.searchParam.patrolYear) {
      //   window.getApp.$emit('ALERT', {
      //     title: 'LBLGUIDE', // 안내
      //     message: '기준년도를 선택하세요.', // 기준년도를 선택하세요
      //     type: 'warning', // success / info / warning / error
      //   });
      //   return;
      // }
      this.$http.url = this.listUrl;
      this.$http.type = 'GET';
      this.$http.param = this.searchParam;
      this.$http.request((_result) => {
        this.grid.data = [];
        this.chart.series = [];
        this.$_.forEach(_result.data, item => {
          this.chart.series.push({
            name:item.patrolResultName,
            data: [item.cnt1,item.cnt2,item.cnt3,item.cnt4,item.cnt5,item.cnt6,item.cnt7,item.cnt8,item.cnt9,item.cnt10,item.cnt11,item.cnt12]
          })
          this.seriesData.push({
            name:item.patrolResultCd,
            data: ['01','02','03','04','05','06','07','08','09','10','11','12']
          })
        })
        this.$refs['chart'].refresh();
      },);
    },
    clickDataPoint(event, chartContext, config) {
      if (config.globals.series[config.seriesIndex][config.dataPointIndex] > 0) {
        this.$http.url = this.detailUrl;
        this.$http.type = 'GET';
        this.$http.param = {
          patrolResultCd: this.seriesData[config.seriesIndex].name,
          patrolYear: this.searchParam.patrolYear,
          patrolMonth: this.seriesData[config.seriesIndex].data[config.dataPointIndex]
        };
        this.$http.request((_result) => {
          this.grid.data = _result.data;
        },);
      } else {
        this.grid.data = [];
      }
    },
    setSize() {
      let height = window.innerHeight - 324;
      height = height >= 400 ? height : 400
      this.chartHeight = height;
    },
  }
};
</script>
